@import 'vars';

.aboutUsContainer {
  width: 100vw;
  background: $blueGradient;
  margin-top: 340px;
  height: 392px;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 1440px;
  padding: 40px 80px 0 80px;
  height: 392px;
}

.textContainer {
  max-width: 288px;
  margin-right: 40px;
  color: $white;
  display: flex;
  flex-direction: column;
}
.header {
  @extend .boldH2;
  margin-top: 0;
  margin-bottom: 16px;
}

.text {
  @extend .regularP1;
  text-align: justify;
}

.linkShowMore {
  margin-left: auto;
  margin-bottom: 40px;
  @extend .semiboldP2;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    margin-left: 8px;
    transform: rotate(270deg);
  }
}

.imagesContainer {
  width: 100%;
  height: 100%;
}

.imagesWrapper {
  position: relative;
  height: 100%;
}

.personFirst {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 688px;
}

.personSecond {
  position: absolute;
  bottom: 0;
  left: 300px;
  height: 688px;
  z-index: 1;
}

.personThird {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 688px;
}

@media screen and (max-width: 1365px) {
  .aboutUsContainer {
    margin-top: 230px;
  }
  .personFirst,
  .personSecond,
  .personThird {
    height: 590px;
  }
  .personSecond {
    left: 200px;
  }
  .personThird {
    right: 160px;
  }
}

@media screen and (max-width: 1025px) {
  .personFirst,
  .personSecond,
  .personThird {
    height: 560px;
  }
  .personFirst {
    left: -50px;
  }
  .personSecond {
    left: 120px;
  }
  .personThird {
    right: 250px;
  }
}

@media screen and (max-width: 979px) {
  .aboutUsContainer {
    background: $white;
    margin-top: 0;
    height: auto;
  }
  .wrapper {
    width: 100%;
    height: auto;
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .textContainer {
    margin: 0;
    color: $blue;
    padding: 0 16px;
    max-width: 100%;
  }
  .linkShowMore {
    img {
      transform: rotate(0);
    }
  }
  .header {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }

  .text {
    font-size: 14px;
    line-height: 21px;
    margin: 0;
  }

  .imagesContainer {
    margin-top: 150px;
    height: 140px;
    width: 100%;
    background: $blueGradient;
  }
  .imagesWrapper {
    width: 460px;
    height: 100%;
    margin: 0 auto;
  }
  .personFirst,
  .personSecond,
  .personThird {
    height: 280px;
  }

  .personFirst {
    left: 0;
  }
  .personSecond {
    left: 132px;
  }
  .personThird {
    height: 295px;
    right: 0;
    bottom: -15px;
  }
}

@media (max-width: 750px) {
  .imagesWrapper {
    width: 360px;
    height: 140px;
    position: relative;
    margin: 0 auto;
  }
  .personSecond {
    left: 84px;
  }
}
