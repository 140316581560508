@import 'vars';

.cardWrapper {
  display: flex;
  flex-wrap: wrap;
  cursor: pointer;
}

div.cardWrapper.wide {
  flex-wrap: nowrap;
}

.wideLinkArchive {
  position: relative;
  cursor: pointer;
  .showMore {
    cursor: pointer;
    margin-top: auto;
    margin-left: auto;
    align-self: flex-end;
  }
}
.flexContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.card {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16), 4px -4px 16px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  cursor: pointer;
  transition: box-shadow 0.2s linear;
  margin-bottom: 32px;

  &:hover {
    box-shadow: 9px 9px 16px rgba(0, 0, 0, 0.16),
      0px 2px 8px rgba(0, 0, 0, 0.16);
  }

  &:active {
    background-color: $gray-50;
    box-shadow: 4px -4px 16px rgba(0, 0, 0, 0.02),
      0px 2px 8px rgba(0, 0, 0, 0.16);
  }
}

.wide {
  width: 1112px;
  min-height: 136px;
  height: auto;
  .imageWrapper {
    min-width: 336px;
    max-width: 336px;
    height: auto;
    position: relative;
  }
}
.small {
  width: 416px;
  height: auto;
  align-content: flex-start;

  .topContainer {
    margin: 16px 0;
    height: auto;
    align-content: flex-start;
  }
}

.big {
  width: 800px;
  height: auto;
  img {
    width: 300px;
  }
}

.bigCard {
  height: auto;
}

.imageWrapper {
  height: 216px;
  width: 416px;
  position: relative;
  img {
    object-fit: cover;
    width: 416px;
    height: 100%;
  }
}

.bigImageWrapper {
  width: 800px;
  height: 523px;
  img {
    object-fit: cover;
    width: 800px;
    height: 100%;
  }
}

.topContainer {
  display: flex;
  width: 100%;
  align-items: center;
}
.label {
  border-radius: 0;
  padding: 0 24px;
  background: $blue;
  color: $white;
  text-transform: capitalize;
  font-size: 16px;
  line-height: 24px;
  margin-right: 26px;
  height: 24px;
}

.date {
  margin: 16px 16px 16px 0;
  color: $gray-500;
  @extend .regularP1;
}

.wideDate {
  margin-left: 16px;
}

.dateSmall {
  margin: 0;
}

.title {
  color: $gray-600;
  margin: 0 16px 24px 16px;
  &.bigTitle {
    @extend .boldH3;
  }
  &.smallTitle {
    @extend .boldH5;
  }
}

.content {
  margin: 16px 16px 32px 16px;
  color: $gray-600;
  @extend .regularP1;
}

.superTitle {
  @extend .regularP2;
  font-weight: 300;
  margin: 16px;
  color: $gray-300;
}

.wide .cardWrapper {
  flex-wrap: wrap;
}

.wideLinkArchive {
  display: flex;
  margin-top: 16px;
  flex-grow: 1;
}
.smallTitle {
  flex-wrap: 1;
}

.showMore {
  text-transform: uppercase;
  color: $blue;
  background: transparent;
  outline: none;
  border: none;
  @extend .semiboldP2;
  align-self: flex-end;
  margin: 0 32px 20px auto;
  height: 24px;
  display: flex;
  align-items: center;
  img {
    margin-left: 8px;
  }
}

@media screen and (max-width: 1365px) {
  .cardWrapper {
    width: 100vw;
    height: auto;
    align-content: flex-start;
  }
  .wide .cardWrapper {
    margin-bottom: 0;
  }
  .small {
    height: auto;
    width: 100%;
  }

  .big {
    width: 100%;
  }

  .wide {
    width: calc(100vw - 16px);
    height: auto;
    .imageWrapper {
      height: auto;
    }
  }

  .wideLinkArchive {
    margin-top: 0px;
    display: block;
    > div:first-child {
      width: 100%;
      margin: 16px 0;
    }
    .date {
      margin-top: 8px;
      margin-bottom: 0;
    }
    .showMore {
      position: relative;
      margin-right: 16px;
    }
  }
  .imageWrapper {
    height: 230px;
    width: 100%;
  }
}

@media screen and (max-width: 979px) {
  .small {
    height: auto;
    width: calc(100vw - 32px);
  }
  .imageWrapper {
    height: 380px;
    width: 100%;
  }
  .wide {
    width: 327px;
    height: auto;
    .imageWrapper {
      height: 176px;
    }
  }
}

@media (max-width: 700px) {
  .card {
    width: 100%;
  }
}

@media screen and (max-width: 760px) {
  .cardWrapper {
    width: 328px;
    height: auto;
    align-content: flex-start;
  }
  .wide .cardWrapper {
    margin-bottom: 0;
  }
  .small {
    height: auto;
    width: 328px;
  }
  .wide {
    width: 327px;
    height: auto;
    .imageWrapper {
      height: 176px;
    }
  }

  .wideLinkArchive {
    margin-top: 0px;
    display: block;
    > div:first-child {
      width: 100%;
      margin: 16px 0;
    }
    .date {
      margin-top: 8px;
      margin-bottom: 0;
    }
    .showMore {
      position: relative;
      margin-right: 16px;
    }
  }
  .imageWrapper {
    height: 180px;
  }
}
